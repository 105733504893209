import axios from "axios";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Button from "@ui/Button";
import Modal from "@ui/Modal";
import Spinner from "@ui/Spinner";

import { actTypeNameMap, labels, toastOptions } from "@constants";

import { selectModalData } from "@reducers/dataTransferSlice";
import { selectTokens } from "@reducers/eafSlice";
import { hideModal } from "@reducers/modalsSlice";
import { setSignedDocument } from "@reducers/validationSlice";

import { formatLabel } from "@utils";
import { prepareAndFinalizeApi } from "@utils/api/prepareAndFinalizeApi";

const Signature = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signatureData, setSignatureData] = useState<FormData>(new FormData());
  const [signatureUrl, setSignatureUrl] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [loadingSignature, setLoadingSignature] = useState(true);
  const [documents, setDocuments] = useState<string[]>([]);
  const data = useSelector(selectModalData);
  const [actData, setActData] = useState<Record<string, string>>({});

  const tokens = useSelector(selectTokens);
  const token = tokens?.[0];

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const actId = queryParams.get("actId") || "";

  const signingSuccessful = () => {
    if (actData?.type) {
      const normalizedActType = actTypeNameMap[actData.type];

      if (["digital", "convention"].includes(normalizedActType)) {
        navigate(`/acts/${normalizedActType}`);
        return;
      }
    }

    toast.success(labels.successfullySignedAct, toastOptions);

    dispatch(hideModal());
    dispatch(setSignedDocument(true));
  };

  useEffect(() => {
    axios
      .get("/api/v1/me/signature", {
        responseType: "blob",
      })
      .then((response) => {
        if (response.data && response.data instanceof Blob) {
          // Create a URL for the Blob
          const url = URL.createObjectURL(response.data);

          signatureData.append("appearance", response.data, "signature.pdf");

          setSignatureData(signatureData);
          setSignatureUrl(url);
          setLoadingSignature(false);
        }
      })
      .catch(() => {
        setLoadingSignature(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!actId) {
      return;
    }

    axios
      .get(`/api/v1/signbooks/${actId}`)
      .then((response) => {
        setActData(response.data);
      })
      .catch(() => {
        setActData({});
      });
  }, [actId]);

  useEffect(() => {
    if (!data?.documents) {
      return;
    }

    setDocuments(data.documents);
  }, [data]);

  const handlePrepareAndFinalize = () => {
    const certificateCode = token?.certificate?.original;

    if (!certificateCode || !actId || !signatureData) {
      return;
    }

    setLoading(true);

    prepareAndFinalizeApi(
      actId,
      token,
      signatureData,
      setLoading,
      signingSuccessful,
    );
  };

  const initialValues = {
    placeSignature: false,
  };

  return (
    <Modal width={450} closable={false}>
      <Formik
        initialValues={initialValues}
        onSubmit={() => {
          handlePrepareAndFinalize();
        }}
      >
        <Form>
          <h2 className="headline-lg pr-4 text-center">{labels.signature}</h2>
          <div className="body-sm mt-4 leading-5">
            {formatLabel(
              labels.signingDocumentsList,
              documents.length.toString(),
            )}

            {documents && (
              <ul className="font-bold mt-3 [&>li]:mt-1">
                {documents.map((document, index) => (
                  <li key={index}>{document}</li>
                ))}
              </ul>
            )}

            <div className="text-center mt-8">
              <div className="text-ea-gray-100 border border-ea-gray-100 py-1 px-3 body-lg inline-block rounded w-[260px]">
                {token?.certificate?.alias || labels.noCertificateDetected}
              </div>
            </div>

            <div className="flex items-center justify-center mt-4">
              <label
                className={`flex relative body-md items-center custom-checkbox-container`}
              >
                <Field
                  type="checkbox"
                  name="placeSignature"
                  className="custom-checkbox"
                />
                {labels.applyHandwrittenSignature}
              </label>
            </div>

            <div className="mt-4 relative w-[250px] h-[150px] lg:w-[101px] lg:h-[80px] bg-slate-200 rounded mx-auto">
              {loadingSignature ? (
                <Spinner className="size-4 mx-auto" />
              ) : (
                <img src={signatureUrl} alt="" />
              )}
            </div>

            <div className="mt-8 flex items-center justify-center">
              <Button
                disabled={loading || !token?.certificate?.original}
                className="btn-base btn-primary flex items-center justify-center"
                type="submit"
              >
                {loading && <Spinner className="size-4 mr-4" />}
                {labels.sign}
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default Signature;
